import router from "../../router"
import { gamesDataMenus } from "../../utils/dataMenus"

export default {
    state: {
        leveTwoSelected:{},
        sceneSelected:{},
        numberLeveSelected:0
    },
    mutations: {
        setLeveTwoSelected(state,leve){
            const dat = JSON.parse(JSON.stringify(leve))
          state.leveTwoSelected = dat
        },
        setFindObject(state,{positionObject,object,sceneNumber}){
            const objectFinded = state.leveTwoSelected.scenes[sceneNumber].objectsInScene[positionObject].positions[object]
            if(objectFinded){
                state.leveTwoSelected.scenes[sceneNumber].objectsInScene[positionObject].numberObjectsFinded +=1
                state.leveTwoSelected.scenes[sceneNumber].objectsInScene[positionObject].positions[object].isFinded=true
            }
        },
        setNumberLeveSelected(state,number){
            state.numberLeveSelected = number
        }
    },
    actions: {
        onClearData({commit}) {
            commit("setNumberLeveSelected", 0)
            commit("setLeveTwoSelected", {})
            
        }
        ,
        onResetActualLeveGameTwoOnReset({commit,getters}){
            const leveReset =  gamesDataMenus.gameTwo.levels[getters.getNumberLveSelected]
            commit('setLeveTwoSelected',leveReset)
            
        },
        onResetActualLeveGameTwo({commit,getters}){
            const leveReset =  gamesDataMenus.gameTwo.levels[getters.getNumberLveSelected]
            commit('setLeveTwoSelected',leveReset)
            router.push('/game-two-entry')
        },
        onNextLeveInGameTwo({commit,getters}){
            console.log(gamesDataMenus.gameTwo.levels[getters.getNumberLveSelected+1])
            const nextleve =  gamesDataMenus.gameTwo.levels[getters.getNumberLveSelected+1]
            if(nextleve){
                commit('setNumberLeveSelected',getters.getNumberLveSelected+1)
                commit('setLeveTwoSelected',nextleve)
                router.push('/game-two-entry')
            }
            else{
                router.push('/leve-selector')
            }
        },
        onSelectGameNumber({commit},number){
            commit('setNumberLeveSelected',number)
        },
        onSelectLeveTwo({commit},leve){
            commit('setLeveTwoSelected',leve)
        },
        onFindObject({commit},payload){
            commit('setFindObject',payload)
        }
    },
    getters:{
        getLeveTwoSelected : state => {
            return state.leveTwoSelected
        },
        getNumberLveSelected : state => state.numberLeveSelected
    },
  }
  