<template>
  <MenuHeaderComponentVue :isInsideGame="false" :isAnyOther="true" />
  <div v-if=" getGameInfo.title" class="fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
    <div class="fixed z-50 inset-0 w-screen h-screen">
      <!-- Background cloud´s -->
      <div class="absolute top-0 z-0 w-full h-full">
        <div class="w-full h-2/12"></div>
        <div class="w-full h-10/12 cloudmiddle"></div>
      </div>

      <div class="fixed inset-0 w-full h-full z-10 transform translate-y-2">
        <div class="h-1/12"></div>
        <!-- TITULO -->
        <div class="h-1/12 titletranslate">
          <p
            id="titleGameCurved"
            class="
              z-30
              font-normal
              text-center text-white text-shadow
              sm:text-3xl
              md:text-3xl
              xl:text-5xl
              2xl:text-8xl
              
            "
          >
            {{ getGameInfo.title }}
          </p>
        </div>
        <!-- SWIPER -->
        <div
          class="
            level
            h-8/12
            heigth-Bajo
            z-50
            flex
            justify-center
            items-center
            sm:-mt-20
            md:-mt-20
            xl:-mt-28
          "
        >
          <Swiper
            class="swiper w-full h-full flex justify-center"
            :loop="true"
            :slidesPerView="'auto'"
            :navigation="true"
            :pagination="{ clickable: true }"
            :spaceBetween="90"
            :centeredSlides="true"
            @slideChange="change"
          >
            <swiper-slide
              class="w-9/12 h-full"
              v-for="(level, i) in getGameInfo.levels"
              :key="i"
            >
              <SelectorLevelsCardsVue
                @click="onOpenGameLevel(getGameInfo, level, i)"
                :levelData="level"
              />
            </swiper-slide>
          </Swiper>
        </div>
      </div>
    </div>
    <!-- MUNDO -->
    <div class="mundoHidden absolute z-0 w-full h-full">
      <div class="w-full h-1/2 xl:h-1/5"></div>
      <img
        class="
          w-full
          xl:h-full
          transform
          -translate-y-15
          xl:-translate-y-0
          md:bg-top
          lg:bg-center
          xl:bg-top
        "
        :src="getWorld"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../components/header/MenuHeaderComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.css";
import { gamesDataMenus } from "../utils/dataMenus";
import SelectorLevelsCardsVue from "../components/cards/SelectorLevelsCards.vue";
import router from "../router";
// import ArcText from "arc-text";
export default {
  data() {
    return {
      gamesDataMenus: gamesDataMenus,
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,
    };
  },
  components: {
    MenuHeaderComponentVue,
    Swiper,
    SwiperSlide,
    SelectorLevelsCardsVue,
  },
  computed: {
    ...mapGetters(["getBackgroundStyle", "getGameInfo", "getWorld"]),
  },
  methods: {
    ...mapActions([
      "openGame",
      "onSelectedLeveGameOne",
      "onSelectedLeveGameThree",
      "defaultInfoGameThree",
      "onSelectLeveTwo",
      "onSelectGameNumber",
    ]),
    onOpenGameLevel(infoGame, level, numberLeve) {
      switch (infoGame.title) {
        case "Lenguaje con Zac":
          this.onSelectedLeveGameOne(level);
          break;
        case "¿Dónde está ZAC?":
          this.onSelectLeveTwo(level);
          this.onSelectGameNumber(numberLeve);
          break;
        case "Escape de Tuberías":
          // this.onSelectedLeveGameThree(level)
          this.defaultInfoGameThree(level);
          break;
        default:
          break;
      }
      router.push(infoGame.game);
    },
  },
  created() {    
     if(this.getWorld === ""){
      router.push("/")
    }
  },
};
</script>

<style>
.background-zac-dice {
  background: linear-gradient(153.43deg, #fecb00 16.67%, #ffb636 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.leveSelector .swiper-button-next {
  top: 25% !important;
}
.leveSelector .swiper-button-prev {
  top: 25% !important;
}

/* .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 48% !important;
} */
</style>