<template>
<MenuHeaderComponentVue :isAnyOther="true" />
   <div class=" fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
     <div class="absolute top-0 z-0 w-full h-full">
        <div class="w-full h-2/12"></div>
        <div class="w-full h-10/12 object-fill cloudmiddle"></div>
        <div class="h-1/12">
          <div class="w-full h-full flex flex-col justify-between"></div>
        </div>
      </div>
     <div class="fixed inset-0 w-full h-full z-10">
        <div class="w-full h-1/12 "></div>
        <div class="w-full h-2/12  flex flex-col justify-center items-center">
            <p class="text-center text-white text-xl
              sm:text-3xl
              md:text-4xl
              xl:text-5xl
              2xl:text-8xl
              md:tracking-wide
              font-normal text-shadow">Escape de Tuberías</p>
            <p class="text-center text-white font-normal
        text-xl
        sm:text-sm
        md:text-base
        xl:text-2xl
        2xl:text-5xl
        text-shadow">Selecciona el nivel que deseas jugar</p>
        </div>
        <div class="w-full h-5/12 ">
            <div class='w-full h-full grid grid-cols-2 gap-2'>
                <div class="w-full h-full " v-for="x,num in getLeveSelectedGameThree.scenes" :key="x">
                    <div @click='selectWorld(num)' class="w-full h-full object-circle-bg flex justify-center items-center">
                        <div class="text-white text-8xl">{{num+1}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full h-4/12 ">
            <img class="h-full w-full object-contain object-bottom" :src="getLeveSelectedGameThree.imgSceneSlector" alt="">
        </div>
     </div>
  </div>
</template>

<script>
import {  mapActions, mapGetters } from 'vuex'
import MenuHeaderComponentVue from '../../components/header/MenuHeaderComponent.vue'
import router from '../../router'
export default {
    components: {
        MenuHeaderComponentVue,
    },
    methods: {
        ...mapActions(['onSelectInitScene','onSelectedLeveGameThree']),
        selectWorld(leve){
            this.onSelectInitScene(leve),
            this.onSelectedLeveGameThree(this.getLeveSelectedGameThree)
            router.push('/game-three-instrucciones')
        }
    },
    computed: {
        ...mapGetters(['getLeveSelectedGameThree','getBackgroundStyle'])
    },
     mounted () {
    if(!this.getLeveSelectedGameThree.imgSceneSlector){
       
      router.push("/")
    }
  },
}
</script>

<style>

</style>