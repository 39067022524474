<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div class="fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
    <!-- Nubes -->
    <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>
    <!-- Contenedor -->

    <div class="fixed inset-0 z-20 top-0 w-full h-full">
      <!-- titulo -->
      <div class="w-full h-2/12 flex justify-center items-end pb-4 titletranslate">
        <p
          id="curveTitleInstruction"
          class="
            
            
            font-normal
            text-white text-shadow
            sm:text-3xl
            md:text-3xl
            xl:text-5xl
            2xl:text-8xl
            
          "
        >
          {{ getGameInfo.title }}
        </p>
      </div>

      <!-- circulo -->
      <div class="flex justify-center">
        <div class="mt-10 rounded-full shadow-background-circle">
          <div
            class="
              w-52
              h-52
              sm:w-52 sm:h-52
              md:w-64 md:h-64
              lg:w-72 lg:h-72
              xl:w-96 xl:h-96
              2xl:w-104 2xl:h-104
              p-5
              background-circle
              rounded-full
              flex flex-col
              justify-center
              items-start
            "
          >
            <!-- Contenedor de la img -->
            <div class="w-full h-auto flex items-center">
              <div
                class="w-full h-full flex flex-col justify-around items-center"
              >
                <!-- <div class="h-1/3  2xl:h-1/2">
                <img
                  class="hidden w-full  2xl:w-80 h-full transform "
                  src="../assets/iconos/tabInstruction.png"
                />
              </div> -->
                <div class="">
                  <p
                    class="
                      font-normal
                      text-center text-white text-sm
                      md:text-base
                      xl:text-2xl
                      2xl:text-6xl
                      text-shadow
                      h-full
                    "
                  >
                    {{ getGameInfo.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- img ¿Deseas continuar -->
      <div
        class="
        
          w-full
          h-3/12
          transform
          traslate5
          -translate-y-1/4
          flex
          justify-center
          items-center
        "
      >
        <img class="h-full" :src="getGameInfo.instructionConfirm" alt="" />
      </div>
      <!-- Contenedor de botones -->
      <div
        class="
          w-full
          h-2/12
          flex
          transform
          -translate-y-2/4
          justify-evenly justify-even
          translate25
          items-center
        "
      >
        <div
          class="
            w-15
            sm:w-16
            botonesAcept
            xl:w-20
            2xl:w-96
            h-14
            flex
            justify-center
            items-center
          "
        >
          <img
            @click="backGames"
            class="object-contain"
            src="../assets/btns/btn-games.png"
            alt=""
          />
        </div>
        <div
          @click="toselectLevel"
          class="
            w-15
            sm:w-16
            botonesAcept
            xl:w-20
            2xl:w-96
            h-14
            flex
            justify-center
            items-center
          "
        >
          <img
            class="object-contain"
            src="../assets/btns/btn-play.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <!-- MUNDO  -->
    <div class="mundoHidden absolute z-0 w-full h-full">
      <div class="w-full h-1/2 xl:h-1/5"></div>
      <img
        class="
          w-full
          xl:h-full
          transform
          -translate-y-15
          xl:-translate-y-0
          md:bg-top
          lg:bg-center
          xl:bg-top
        "
        :src="getWorld"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../components/header/MenuHeaderComponent.vue";
import router from "../router";
// import ArcText from "arc-text";
export default {
  data() {
    return {
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,
    };
  },
  components: {
    MenuHeaderComponentVue,
  },
  computed: {
    ...mapGetters(["getGameInfo", "getBackgroundStyle", "getWorld"]),
  },
  methods: {
    ...mapActions(["openGame"]),
    toselectLevel() {
      router.push("/leve-selector");
    },
    backGames() {
      router.back();
    },
  },
  mounted () {
    if(!this.getGameInfo.title ){
      router.push("/")
    }
  },
};
</script>

<style>
/* .world-instruction {
  background-image: url("../assets/fondos/world1.png");
  background-size: cover;
  background-repeat: no-repeat;
} */
.cloud-instructions {
  background-image: url("../assets/fondos/cloud1.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.bg-btns {
  background: linear-gradient(153.43deg, #ffb636 16.67%, #ff9420 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
</style>