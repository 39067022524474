<template>
  <!-- :style="{ 'background-image': 'url(' + gameData.cardImgAnimal + ')' }" -->
  <!-- tarjeta de seleccion de juego -->
  <div
    @click="onOpeninstructions"
    class="
      transform
      flex flex-col
      z-0
      
      justify-evenly
      items-center
      w-full
      h-full
      translateCircleGame
    "
  >
    <!-- Background circular de Zac esto vino a reemplazar el cuadro naranja con la suricata -->
    <div
      class="
        sm:mt-24
        md:mt-16
        
        rounded-full
        shadow-background-circle
        
      "
    >
      <div
        class="
          w-52
          h-52
          sm:w-52 sm:h-52
          md:w-64 md:h-64
          lg:w-72 lg:h-72
          xl:w-96 xl:h-96
          2xl:w-104 2xl:h-104
          p-5
          background-circle
          rounded-full
          flex flex-col
          justify-center
          items-start
        "
      >
        <!-- Contenedor de la img -->
        <div class="w-full h-auto flex justify-center items-center">
          <!-- IMG Zac -->
          <img
            class="object-cover"
            :class="
              gameData.title === 'Escape de Tuberías'
                ? 'w-2/3'
                : 'w-28 lg:w-32 xl:w-36 2xl:w-96'
            "
            :src="gameData.img"
            alt=""
          />
        </div>
      </div>
    </div>
    <!-- subtitulo -->
    <p
      class="
        subTitle664
        text-center
        transform
        w-full
        text-white
        font-normal
        text-xl
        sm:text-sm
        md:text-2xl
        2xl:text-6xl
        text-shadow
      "
      v-on:change="mounted()"
    >
      {{ gameData.title }}
      <!-- Lenguaje con Zac -->
    </p>
  </div>
</template>
  

<script>
import { mapActions } from "vuex";
// import ArcText from "arc-text";
export default {
  props: {
    gameData: {
      default: {},
      required: true,
    },
  },
  methods: {
    ...mapActions(["onSetGameInfo"]),
    onOpeninstructions() {
      if (this.gameData.description !== "PRÓXIMAMENTE") {
        this.onSetGameInfo(this.gameData);
      } else console.log("no seleccionable");
    },
  },
  mounted() {
    // const arcText = new ArcText(document.querySelector(".curveTitle"));
    // // Set the radius to 150 pixels.
    // arcText.arc(300);
    // arcText.direction(-1);
  },
};
</script>

<style>
.background-cards-games-selec {
  /* background: linear-gradient(153.43deg, #FECB00 16.67%, #FFB636 100%); */
  background-position: center;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
</style>