<template>
  <MenuHeaderComponentVue :isInsideGame="false" />
  <div class="age fixed inset-0 bg-white ">
    <Swiper
      class="mySwiper"
      :loop="true"
      :slidesPerView="1"
      :effect="'fade'"
      :navigation="true"
      :pagination="{ clickable: true }"
      :spaceBetween="0"
    >
      <swiper-slide>
        <div
          @click="
            toSelecotorGame(
              'bg-gradient-to-b from-8fd9ff to-0ab2ff',
              '0-2',
              './img/worlds/worldGame.png'
            )
          "
          class="w-screen h-screen bg-gradient-to-b from-8fd9ff to-0ab2ff"
          :key="'1'"
        >
          <AgeCardsGamesVue
            :idCurve="'cloudone'"
            :clouds="'./img/background/cloud1.png'"
            :world="'./img/worlds/worldGame.png'"
            :text="'./img/worlds/textwr1.png'"
            :star="'./img/worlds/start1.png'"
            :imgAge="'./img/worlds/gameStarOne.png'"
            :ageTitle="'Hasta 2 años'"
            :curve="'curve'"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @click="
            toSelecotorGame(
              'bg-gradient-to-b from-ffe764 to-ffa818',
              '3-6',
              './img/worlds/worldGame2.png'
            )
          "
          class="w-screen h-screen bg-gradient-to-b from-ffe764 to-ffa818"
          :key="'2'"
        >
          <AgeCardsGamesVue
            :clouds="'./img/background/cloud1.png'"
            :world="'./img/worlds/worldGame2.png'"
            :text="'./img/worlds/text2.png'"
            :star="'./img/worlds/star2.png'"
            :imgAge="'./img/worlds/gameStarTwo.png'"
            :ageTitle="'3 a 6 años'"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @click="
            toSelecotorGame(
              'bg-gradient-to-b from-ffaf0c to-ff0753',
              '7-10',
              './img/worlds/worldGame3.png'
            )
          "
          class="w-screen h-screen bg-gradient-to-b from-ffaf0c to-ff0753"
          :key="'3'"
        >
          <AgeCardsGamesVue
            :clouds="'./img/background/cloud1.png'"
            :world="'./img/worlds/worldGame3.png'"
            :text="'./img/worlds/text3.png'"
            :star="'./img/worlds/star3.png'"
            :imgAge="'./img/worlds/gameStarThree.png'"
            :ageTitle="'7 a 10 años'"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @click="
            openLinkExternal(
              'https://3destiny.8thwall.app/conozcamosjuntosazac'
            )
          "
          class="panel w-screen h-screen bg-gradient-to-b from-189eff to-6234ff"
          :key="'4'"
        >
          <AgeCardsGamesVue
            :clouds="'./img/background/cloud1.png'"
            :isSkyStarts="true"
            :isButonNotStar="true"
            :world="'./img/worlds/worldGame4.png'"
            :text="'./img/worlds/text4.png'"
            :imgAge="'./img/worlds/zacTablet.png'"
            :ageTitle="'Conozcamos juntos a Zac'"
          />
        </div>
      </swiper-slide>
      <swiper-slide>
        <div
          @click="openLinkExternal('https://3destiny.8thwall.app/snapzac')"
          class="panel w-screen h-screen bg-gradient-to-b from-3327be to-9312c3"
          :key="'5'"
        >
          <AgeCardsGamesVue
            :clouds="'./img/background/cloud1.png'"
            :isSkyStarts="true"
            :isButonNotStar="true"
            :world="'./img/worlds/worldGame5.png'"
            :text="'./img/worlds/text5.png'"
            :imgAge="'./img/worlds/zacTablet.png'"
            :ageTitle="'SnapZac'"
          />
        </div>
      </swiper-slide>
    </Swiper>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../components/header/MenuHeaderComponent.vue";
import AgeCardsGamesVue from "../components/cards/AgeCardsGames.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.css";
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";

import ArcText from "arc-text";
import router from '../router';

SwiperCore.use([EffectFade, Navigation, Pagination]);

export default {
  components: {
    MenuHeaderComponentVue,
    Swiper,
    SwiperSlide,
    AgeCardsGamesVue,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['getEntryCero'])
  },
  methods: {
    ...mapActions(["onSetBackgoundStyle", "onSelectAge", "onSetWorld"]),
    toSelecotorGame(backgroundStyle, age, world) {
      this.onSetBackgoundStyle(backgroundStyle);
      this.onSelectAge(age);
      this.onSetWorld(world);
      this.$router.push("/selectorgame");
    },
    openLinkExternal(link) {
      window.open(link);
    },
    onMoveEnd() {},
  },
  mounted() {
    if(this.getEntryCero){
      router.push("/")
    }
    // Continuar

    let curve = document.getElementsByClassName("curve");
    for (let i = 0; i <= curve.length; i++) {
      const arcText = new ArcText(document.querySelector(".curve"));
      let size = 0;

      this.windowWidth <= 374
        ? (size = 200)
        : this.windowWidth < 820
        ? (size = 210)
        : this.windowWidth < 1025
        ? (size = 300)
        : this.windowWidth > 1025
        ? (size = 700)
        : (size = 600);

      arcText.arc(size);
      window.onresize = () => {
        this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
      };
    }
  },
};
</script>

<style>
.age .swiper-horizontal > .swiper-pagination-bullets {
  bottom: 43% !important;
}

.age .swiper-button-next {
  top: 35% !important;
}

.age .swiper-button-prev {
  top: 35% !important;
}
</style>