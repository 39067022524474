<template>
  <MenuHeaderComponentVue :isAnyOther="true"/>
  <div class="fixed inset-0 w-screen h-screen flex justify-center items-center">
    <div class="w-full h-full bg-contain" :class="getBackgroundStyle"></div>
    <div class="absolute top-0 z-0 w-full h-full">
      <div class="w-full h-2/12"></div>
      <div class="w-full h-10/12 cloudmiddle"></div>
    </div>
    <div class="fixed top-0 w-full p-3 h-full flex justify-center items-center">
      <div class="w-full h-full">
        <div class="w-full h-1/12"></div>
        <div class="w-full h-1/12 flex justify-center items-center"></div>
        <div class="w-full h-3/12 flex flex-col justify-center items-center">
          <div class="w-full h-2/12 flex justify-center items-end pb-4 transform xl:translate-y-full">
            <p
              id="curveTitleInstruction"
              class="
                font-normal
                transform
                translate-y-3/4
                sm:translate-y-1/4
                xl:translate-y-full
                traslate80
                text-white text-shadow
                sm:text-3xl
                md:text-3xl
                xl:text-5xl
                2xl:text-8xl
              "
            >
              ¡Muchas Felicidades!
            </p>
          </div>
          <!-- TODO:Circulo -->
          <div class="mt-5 sm:mt-16 rounded-full shadow-background-circle">
            <div
              class="
                w-52
                h-52
                sm:w-52 sm:h-52
                md:w-64 md:h-64
                lg:w-72 lg:h-72
                xl:w-96 xl:h-96
                2xl:w-104 2xl:h-104
                p-5
                background-circle
                rounded-full
                flex flex-col
                justify-center
                items-center
              "
            >
              <div
                class="
                  h-9/12
                  w-full
                  z-10
                  transform
                  -translate-y-1/4
                  2xl:-translate-y-20
                  flex flex-col
                  justify-center
                  items-center
                  px-4
                "
              >
                <img
                  class="xl:w-56 2xl:w-80"
                  src="../../assets/objetos/trofeo.png"
                />
                <p
                  class="
                    font-normal
                    text-center text-white text-sm
                    md:text-base
                    xl:text-2xl
                    2xl:text-6xl
                    text-shadow
                    h-full
                    w-full
                  "
                >
                  Has encontrado todos los objetos
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col justify-center">
          <!-- img Descubre mas niveles -->
          <div class="flex justify-center  transform xl:translate-y-1/4">
            <div class="w-full xl:w-6/12 flex justify-center">
              <div class="w-full flex justify-center">
                <img
                  @click="goTogames"
                  class="traslate moreLevelSm 2xl:w-104"
                  src="../../../public/img/instructions/moreLevels.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <!-- Contenedor de botones -->
          <div class="traslate mt1Rem z-50 flex justify-center  transform xl:translate-y-1/4">
            <!-- btn repetir -->
            <button class="w-2/4 h-8/12 flex justify-center items-center">
              <img
                class="sm:w-1/3 md:w-auto 2xl:w-3/12"
                @click="onResetActualLeveGameTwo"
                src="../../assets/btns/btnRep.png"
                alt=""
              />
            </button>
            <!-- next world -->
            <button
              @click="nextWorld"
              class="w-2/4 h-8/12 flex justify-center items-center"
            >
              <img
                class="sm:w-1/3 md:w-auto 2xl:w-3/12"
                src="../../assets/btns/btnBack.png"
                alt=""
              />
            </button>
          </div>
        </div>
        <div class="z-10">
          <div class="flex justify-center">
            <img
              class="z-0 2xl:w-full transform -translate-y-1/4"
              :src="getLeveTwoSelected.background"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
// import ArcText from "arc-text";

export default {
  data() {
    return {
      textnumber: 1,
      menuEnd: false,
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,
    };
  },
  components: {
    MenuHeaderComponentVue,
  },
  computed: {
    ...mapGetters(["getLeveTwoSelected", "getGameInfo", "getBackgroundStyle"]),
  },
  methods: {
    ...mapActions(["onNextLeveInGameTwo", "onResetActualLeveGameTwo"]),
    nextWorld() {
      this.onNextLeveInGameTwo();
    },
    nextTextEntry() {
      this.textnumber = this.textnumber += 1;
      if (this.textnumber >= 3) {
        this.menuEnd = true;
      }
    },
    // /leve-selector
    goToLeveSelector() {
      router.push("/selector-edad");
    },
    goTogames() {
      router.push("/leve-selector");
    },
  },
  created() {
  // console.log(this.getLeveTwoSelected.scenes)
    if(!this.getLeveTwoSelected.background){
      router.push("/")
    }
  },
};
</script>

<style>
.btn-style {
  background: linear-gradient(153.43deg, #ffb636 16.67%, #ff9420 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.card-text-win {
  background-image: url("../../assets/fondos/card3.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.card-text-win-green {
  background-image: url("../../assets/fondos/card3green.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
</style>