import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import PrivacyView from '../views/PrivacyView.vue'
import SelectorAgeview from '../views/SelectorAgeView'
import InstructionView from '../views/InstructionView.vue'
import GameOne from '../views/Games/GameOne.vue'
import SelectorGames from '../views/SelectorGames.vue'
import SelectorLevelView from '../views/SelectorLevelView'
import GameThree from '../views/Games/GameThree.vue'
import GameThreeWin from '../views/Games/GameThreeWin.vue'
import  HomeParentingView from '../views/parenting/HomeParentingView.vue'
import LivinAmeInstructions from '../views/parenting/LivinAmeInstructions.vue'
import MedicAssociationsEntry from '../views/parenting/MedicAssociationsEntry.vue'
import MedicAssociationsOptions from '../views/parenting/MedicAssociationsOptions.vue'
import CardsLivingAme from '../views/parenting/CardsLivingAme.vue'
import GameThreeWinText from '../views/Games/GameThreeWinText.vue'
import GameTwoEntry from '../views/Games/GameTwoEntry.vue'
import Login from '../views/cms/Login.vue'
import CmsView from '../views/cms/CmsView.vue'
import CmsCreatorTips from '../views/cms/CmsCreatorTips.vue'
import GameTwo from '../views/Games/GameTwo.vue'
import GameTwoWinView from '../views/Games/GameTwoWinView.vue'
import GameThreeEntry from '../views/Games/GameThreeEntry.vue'
import GameThreeInstrucctions from '../views/Games/GameThreeInstrucctions.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Login
  },
  {
    path: '/cms',
    name: 'Cms',
    component: CmsView,
    meta:{protected :true}
  },
  {
    path: '/cms-creator-tips',
    name: 'CmsCreatorTips',
    component: CmsCreatorTips,
    
  },
  {
    path: '/parenting',
    name: 'parenting',
    component: HomeParentingView
  },
  {
    path: '/medic-associations',
    name: 'medicAssociations',
    component: MedicAssociationsEntry
  },
  {
    path: '/medic-options',
    name: 'medicAssociationsOptions',
    component: MedicAssociationsOptions
  },
  {
    path: '/livingame-instructions',
    name: 'livingame-instructions',
    component: LivinAmeInstructions
  },
  {
    path: '/cardsLivingAme',
    name: 'CardsLivingAme',
    component: CardsLivingAme
  },
  {
    path: '/privacy',
    name:'Privacy',
    component: PrivacyView
  },
  {
    path: '/selectorgame',
    name:'selectorgame',
    component: SelectorGames
  },
  {
    path: '/selector-edad',
    name:'selector-edad',
    component:SelectorAgeview,
  },
  {
    path: '/instrucciones',
    name:'instrucciones',
    component:InstructionView,
  },
  { //game one 
    path: '/game-lenguaje',
    name:'game-lenguaje',
    component:GameOne,
  },
  { //game two 
    path: '/game-two-entry',
    name:'game-two-entry',
    component:GameTwoEntry,
  },
  { //game two 
    path: '/game-two-win',
    name:'game-two-win',
    component:GameTwoWinView,
  },
  { //game two 
    path: '/game-two',
    name:'game-two',
    component:GameTwo,
  },
  { //game three 
    path: '/game-three-leve-selector',
    name:'game-three-leve-selector',
    component:GameThreeEntry,
  },
  { //game three 
    path: '/game-three-instrucciones',
    name:'game-three-instrucciones',
    component:GameThreeInstrucctions,
  },
  { //game three 
    path: '/game-tuberias',
    name:'game-tuberias',
    component:GameThree,
  },
  { //game three 
    path: '/game-tuberias-win',
    name:'game-tuberias-win',
    component:GameThreeWin,
  },
  { //game three 
    path: '/game-tuberias-win-text',
    name:'game-tuberias-win-text',
    component:GameThreeWinText,
  },
  {
    path: '/leve-selector',
    name:'leve-selector',
    component:SelectorLevelView,
  },
]



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
  if(to.meta.protected){
    if(localStorage.getItem("isLogged")){
      next();
    }
    else{
      next("/admin");
    }
  }
  else{
    next();
  }
})

export default router
