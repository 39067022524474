<template>
<MenuHeaderComponentVue :isInsideGame="false" :paddingtop="true" />
  <div @click="nextText()" class="fixed inset-0  w-screen h-screen flex justify-center items-center">
     <div class="w-full h-full " :style="{'background-image': 'url(' + getLeveSelectedGameThree.backgroundImg + ')'}"></div>
     <div class="fixed top-0 w-full p-3 h-full  flex justify-center items-center">
       <div class="w-full h-full blur-background-win border  border-white">
         <div class="w-full h-1/12 "></div>
         <div class="w-full h-1/12 flex justify-center items-center ">
         <p class="text-white text-3xl">{{getGameInfo.title}}</p>
         </div>
         <div class="w-full h-1/12 "></div>
         <div class="w-full h-5/12 flex justify-center items-center ">
          <div class="h-full w-4/12 flex justify-center items-end z-30">
            <img class="" :src="getLeveSelectedGameThree.caracterWin" alt="">
          </div>
          <div :class="getLeveSelectedGameThree.id===1?'card-text-win':'card-text-win-green'" class="h-9/12 w-8/12 transform z-10 -translate-x-12  flex justify-center items-center px-4">
            <p v-if="numertext === 0" class="text-center text-white text-xl">{{getLeveSelectedGameThree.textWinOne}}</p>
            <p v-else class="text-center text-white text-xl">{{getLeveSelectedGameThree.textWinTwo}}</p>
          </div>
         </div>
         <div class="w-full h-4/12 ">
          <div class="h-6/12 w-full  flex justify-center items-center">
            <img class="h-full" src="../../assets/personajes/Ceular_RattyHablando.png" alt="">
          </div>
          <div class="h-6/12 w-full ">
          <div class="w-full h-full flex justify-center items-center">
            <img class="object-contain w-20 h-6" src="../../assets/iconos/biogen.png" alt="">
          </div>
          </div>
         </div>
       </div>
     </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MenuHeaderComponentVue from '../../components/header/MenuHeaderComponent.vue'
import router from '../../router'

export default {
  components: {
    MenuHeaderComponentVue,
  },
  data() {
      return {
          numertext: 0
      }
  },
  computed: {
    ...mapGetters(['getLeveSelectedGameThree','getGameInfo'])
  },
  methods: {
    ...mapActions([]),
    nextText(){
           this.numertext++
           if(this.numertext > 1 ){
               router.push('/game-tuberias-win')
               this.numberText = 0
           } 
    }
  },

}
</script>

<style>

</style>