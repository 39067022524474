<template>
  <div
    class="
      fixed
      w-screen
      h-screen
      bg-gradient-to-b
      from-19e4ec
      to-0da2a3
      flex
      justify-center
      items-center
    "
  >
    <div class="absolute top-0 z-10 w-full h-full">
      <div class="w-full h-5/12"></div>
      <div class="w-full h-7/12 cloudmiddle"></div>
    </div>
    <div class="z-40 w-full h-full px-4 py-6">
      <div class="w-full backgroundMenuParenting h-full border-2 border-white">
        <div class="w-full h-1/2">
          <div
            @click="goTo('/selector-edad')"
            class="w-full h-2/12 flex justify-between items-center px-2"
          >
            <img class="h-1/2" src="../../assets/iconos/close.png" alt="" />
            <img
              class="object-contain w-20  xl:w-32  2xl:w-48 h-full"
              src="../../assets/iconos/biogen.png"
              alt=""
            />
            <div></div>
          </div>
          <div
            class="
              w-full
              h-10/12
              flex flex-col
              justify-evenly
              items-center
              px-4
              
            "
          >
            <!-- Viviendo con Ame -->
            <div
              @click="goTo('/cardsLivingAme')"
              class="
                w-4/5
                h-3/12
                flex
                justify-between
                items-center
                border-button-white
              "
            >
              <img
                class="sm:w-14 md:w-16 xl:w-20 2xl:w-40"
                src="../../assets/iconos/livingame.png"
                alt=""
              />
              <p
                class="
                  font-normal
                  text-center text-white text-sm
                  md:text-base
                  xl:text-2xl
                  2xl:text-6xl
                  text-shadow
                  h-full
                "
              >
                Viviendo <br />
                con AME
              </p>
              <div></div>
            </div>

            <!-- Asociaciones medicas -->
            <div
              @click="goTo('/medic-options')"
              class="
                w-4/5
                h-3/12
                flex
                justify-between
                items-center
                border-button-white
              "
            >
              <img
                class="sm:w-14 md:w-20 xl:w-20 2xl:w-40"
                src="../../assets/iconos/estetoscopio.png"
                alt=""
              />
              <p
                class="
                  font-normal
                  text-center text-white text-sm
                  md:text-base
                  xl:text-2xl
                  2xl:text-6xl
                  text-shadow
                  h-full
                "
              >
                Asociaciones <br />
                médicas
              </p>
              <div></div>
            </div>
            <!-- Aviso de privacidad -->
            <div
              @click="goToPrivacy()"
              class="
                w-4/5
                h-3/12
                flex
                justify-between
                items-center
                border-button-white
              "
            >
              <img
                class="sm:w-14 md:w-20 xl:w-20 2xl:w-40"
                src="../../assets/iconos/avisodep.png"
                alt=""
              />
              <p
                class="
                  font-normal
                  text-center text-white text-sm
                  md:text-base
                  xl:text-2xl
                  2xl:text-6xl
                  text-shadow
                  h-full
                "
              >
                Aviso de <br />
                privacidad
              </p>
              <div></div>
            </div>
          </div>
        </div>
        <!-- Area de la img -->
        <div class="w-full h-1/2">
          <div class="w-full h-4/12"></div>
          <div class="w-full h-4/12 flex justify-center items-center">
            <div class="w-3/4 h-1/6 flex justify-center items-center">
              <img
                class="object-contain 2xl:w-35"
                src="../../assets/animals/parentingImg.png"
                alt=""
              />
            </div>
          </div>
          <!-- <div class="w-full h-2/12"></div>
          <div class="w-full h-3/12 flex justify-center items-center">
            <p
              id="curveTextpareting"
              class="text-298988 text-xl md:text-2xl font-normal text-center"
            >
              El Mundo de Zac
            </p>
          </div>
          <div class="w-full h-6/12"></div>
          <div class="w-full h-1/12">
            <div class="flex justify-center items-center">
              <img
                class="object-contain w-20 h-6"
                src="../../assets/iconos/biogen.png"
                alt=""
              />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "../../router";
export default {
  components: {},
  methods: {
    goTo(url) {
      router.push(url);
    },
    goToPrivacy(){
      window.open('https://www.biogen.com.mx/es_MX/privacy-policy.html');
    }
  },
};
</script>

<style>
.border-button-white {
  border-bottom: 3px solid white;
}
.backgroundMenuParenting {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  /* backdrop-filter: blur(42px); */
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 14px;
}
</style>