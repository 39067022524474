<template>
  <MenuHeaderComponentVue :isInsideGame="false" />
  <!-- background__general -->
  <div
    class="
      fixed
      w-screen
      h-screen
      bg-gradient-to-b
      from-8fd9ff
      to-0ab2ff
      flex
      justify-center
      items-center
    "
  >
    <!-- background__nubes -->
    <div class="absolute top-0 z-10 w-full h-full">
      <div class="w-full h-4/12 xl:h-2/12"></div>
      <div class="w-full h-8/12 xl:h-10/12 cloudmiddle"></div>
    </div>
    <!-- contenedor__generalContenido -->
    <div class="fixed transform translate-y-4 inset-0 top-0 z-20 w-full h-full">
      <!-- Contenedor de el circulo y sus textos -->
      <div
        class="
          w-full
          h-6/12
          mt-8
          flex flex-col
          justify-center
          items-center
          pt-3
        "
      >
        <!-- titulo__curvo -->
        <p class="z-0 absolute text-white text-xl">
          <span
            id="curve"
            class="
              sm:text-3xl
              md:text-3xl
              xl:text-5xl
              2xl:text-8xl
              textsm444
              font-normal
              text-center
              transform
              translate-y-3
              text-shadow
              textMdShort
              tracking-normal
              md:tracking-wide
              translateSmShort
            "
            >Bienvenido al Mundo de ZAC</span
          >
          <br />
          <br class="hidden noHidden" />
          <br class="hidden noHidden" />

          <br />
          <!-- <span class="h-full"></span> -->
          <span
            id="curve2"
            class="
              md:text-2xl
              2xl:text-6xl
              font-normal
              text-center
              transform
              md:translate-y-14
              text-shadow
              tracking-normal
              textSmShort
            "
            >¡Acompañanos a esta nueva aventura!</span
          >
        </p>
        <!-- circulo -->
        <div class="rounded-full shadow-background-circle">
          <div
            class="
              w-52
              h-52
              sm:w-52 sm:h-52
              md:w-64 md:h-64
              lg:w-72 lg:h-72
              xl:w-96 xl:h-96
              2xl:w-104 2xl:h-104
              p-5
              background-circle
              rounded-full
              flex flex-col
              justify-center
              items-start
            "
          >
            <!-- Contenedor de la img -->

            <div class="w-full h-auto flex justify-center items-center">
              <div class="p-3 flex flex-col justify-between items-center">
                <p
                  class="
                    shortPrivacy
                    text-center text-shadow text-white text-sm
                    md:text-base
                    xl:text-2xl
                    2xl:text-6xl
                    textSmShort
                  "
                >
                  <span> Un lugar creado especialmente para ti. <br></span>
                  
                  <span>
                    A través de los diferentes mundos podrás aprender y jugar en
                    compañia de ZAC, su familia y amigos.</span
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- contenedor__avisoPrivacidad -->
      <div class="w-full h-1/12 flex justify-center items-center">
        <!-- avisoPrivacidad -->
        <div
          class="w-10/12 h-1/2 t rounded-2xl flex justify-center items-center"
        >
          <p>
            <input v-model="isAcceptedPrivacy" id="check" type="checkbox" />
            <label
              for="check"
              class="
                mx-2
                text-center text-white text-xs
                md:text-sm
                xl:text-2xl
                2xl:text-6xl
              "
            >
              Acepto el
              <a
                target="_blank"
                href="https://www.biogen.com.mx/es_MX/privacy-policy.html"
                ><u>Aviso de Privacidad</u></a
              >
            </label>
          </p>
        </div>
      </div>
      <!-- contenedor__btnAndLogo -->
      <div class="w-full h-3/12 z-10 flex flex-col justify-start items-center">
        <div class="w-4/5 h-4/5 flex justify-center items-center">
          <!-- btn -->
          <button
            @click="goNext()"
            :class="isAcceptedPrivacy ? 'btn-play' : 'btn-play-byn'"
            class="
              w-full
              h-full
              lg:w-5/6 lg:h-full
              rounded-xl
              text-white text-2xl
              lg:text-3xl
              xl:text-2xl
              2xl:text-6xl
            "
          >
            Jugar
          </button>
        </div>
      </div>
    </div>
    <!-- MUNDO -->
    <div class="absolute z-0 w-full h-full">
      <div class="w-full h-1/2"></div>
      <div
        class="w-full h-1/2 md:bg-top lg:bg-center xl:bg-center worldPrivacy"
      ></div>
    </div>
  </div>
</template>

<script>
import MenuHeaderComponentVue from "../components/header/MenuHeaderComponent.vue";
import router from "../router";
import ArcText from "arc-text";
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      windoWHeight: window.innerHeight,
      isAcceptedPrivacy: false,
    };
  },

  components: {
    MenuHeaderComponentVue,
  },
  methods: {
    ...mapActions(['onchangeEntryCero']),
    goNext() {
      if (this.isAcceptedPrivacy) {
        router.push("/selector-edad");
        this.onchangeEntryCero()
      } else {
        alert("Por favor acepta el aviso de privacidad");
      }
    },
  },
  mounted() {
    const arcText = new ArcText(document.getElementById("curve"));
    const arcText2 = new ArcText(document.getElementById("curve2"));
    let size = 0;
    let size2 = 0;
    //  this.windoWHeight == 1024 && this.windowWidth == 768
    //   ? (size = 250)

    //   :this.windoWHeight == 995 && this.windowWidth == 810
    //   ? (size = 260)
    //   : 
      this.windowWidth <= 360
      ? (size = 180)
      : this.windowWidth <= 374
      ? (size = 195)
      : this.windowWidth <= 390
      ? (size = 190)
      : this.windowWidth <= 428
      ? (size = 200)
      : this.windowWidth <= 753
      ? (size = 190)
      : this.windowWidth <= 768
      ? (size = 250)
      : this.windowWidth <= 810
      ? (size = 250)
      : this.windowWidth < 820
      ? (size = 195)
      : this.windowWidth < 1025
      ? (size = 250)
      : this.windowWidth > 1025
      ? (size = 500)
      : (size = 600);
    // this.windowWidth < 374 ? (size2 = 140) : (size2 = 180);
    // this.windowWidth < 374 ? (size2 = 140) : 

    this.windoWHeight == 1024 && this.windowWidth == 768
      ? (size2 = 250)
      : this.windowWidth <= 360
      ? (size2 = 145, size = 145)
      : this.windowWidth <= 374
      ? (size2 = 170)
      : this.windowWidth <= 390
      ? (size2 = 195)
      : this.windowWidth <= 428
      ? (size2 = 190)
      : this.windowWidth <= 768
      ? (size2 = 200)
      : this.windowWidth <= 820
      ? (size2 = 250)
      : this.windowWidth <= 1025
      ? (size2 = 245)
      // : this.windowWidth >= 1037
      // ? (size2 = 200)
      :(size2 = 600);


    arcText.arc(size);
    arcText2.arc(size2);
    arcText2.direction(-1);
    window.onresize = () => {
      this.windowWidth = window.innerWidth; // asigno a la propiedad el valor de el ancho de la ventana
      this.windoWHeight = window.innerWidth;
    };
  },
};
</script>

<style>
.worldPrivacy {
  background-image: url("../../public/img/worlds/worldPrivacy.png");
  background-size: cover;
  background-repeat: no-repeat;
}
.btn-play {
  background-image: url("../assets/btns/btn-generic.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.btn-play-byn {
  background-image: url("../assets/btns/btn-generic-byn.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.shadow-btn-play {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.bg-gradient-white {
  backdrop-filter: blur(42px);
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.2) 0%,
    rgba(255, 255, 255, 0) 100%
  );
}

input[type="checkbox"] {
  display: none;
}

label:before {
  content: "";
  background: white;
  border: 2px solid #ffa801;
  border-radius: 100%;
  display: inline-block;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  text-shadow: 4px -2px 3px gray;
  text-align: center;
  vertical-align: middle;
}
input[type="checkbox"]:checked + label:before {
  content: "✓";
  font-size: 50px;
  color: #ffa801;
}
</style>