<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div
    class="
      fixed
      inset-0
      w-screen
      h-screen
      bg-gradient-to-b
      from-19e4ec
      to-0da2a3
    "
  >
    <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>

    <!-- Contenedor -->
    <div class="fixed inset-0 z-20 top-0 w-full h-full">
      <!-- Titulo Asociaciones médicas -->
      <div class="w-full h-2/12 flex justify-center items-center pb-4">
        <p
          class="
            font-normal
            transform
            translate-y-2/4
            text-white text-shadow
            sm:text-3xl
            md:text-3xl
            xl:text-5xl
            2xl:text-8xl
          "
        >
          Asociaciones médicas
        </p>
      </div>
      <!-- Contenedor cuadricula -->
      <div class="w-full h-7/12 xl:grid xl:gap-5 transform xl:translate-y-10">
        <!-- primer fila -->
        <div class="flex w-full h-1/2 justify-evenly items-center">
          <!-- 1 columna-sociedad mexicana de neurologia... -->
          <div class="h-full">
            <div
              @click="goTo('https://neuropediatria.org.mx/#/home')"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="rounded-full shadow-parenting-circle">
                <div
                  class="
                    w-52
                    h-52
                    sm:w-24 sm:h-24
                    md:w-32 md:h-32
                    lg:w-32 lg:h-32
                    xl:w-52 xl:h-52
                    2xl:w-96 2xl:h-96
                    background-circle-parentig
                    rounded-full
                    flex
                    justify-center
                    items-center
                  "
                >
                  <img
                    class="xl:w-32 2xl:w-52"
                    src="../../assets/iconos/Artboard4.png"
                    alt=""
                  />
                </div>
              </div>
              <img
                class="w-full xl:w-72 2xl:w-35 transform"
                src="../../assets/btns/btn-SMNP.png"
              />
            </div>
          </div>
          <!-- 2 columna-Asociacion mexicana de pediatria -->
          <div class="h-full">
            <div
              @click="goTo('https://amp.org.mx/')"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="rounded-full shadow-parenting-circle">
                <div
                  class="
                    w-52
                    h-52
                    sm:w-24 sm:h-24
                    md:w-32 md:h-32
                    lg:w-32 lg:h-32
                    xl:w-52 xl:h-52
                    2xl:w-96 2xl:h-96
                    background-circle-parentig
                    rounded-full
                    flex
                    justify-center
                    items-center
                  "
                >
                  <img
                    class="xl:w-32 2xl:w-52"
                    src="../../assets/iconos/Artboard1.png"
                    alt=""
                  />
                </div>
              </div>
              <img
                class="w-full xl:w-72 2xl:w-35 transform"
                src="../../assets/btns/btn-AMP.png"
              />
            </div>
          </div>
        </div>
        <!-- 2 fila -->
        <div class="flex w-full h-1/2 justify-evenly items-cente pt-4">
          <!-- 1 columna -->
          <div class="h-full">
            <div
              @click="goTo('https://www.amgh.org.mx/')"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="rounded-full shadow-parenting-circle">
                <div
                  class="
                    w-52
                    h-52
                    sm:w-24 sm:h-24
                    md:w-32 md:h-32
                    lg:w-32 lg:h-32
                    xl:w-52 xl:h-52
                    2xl:w-96 2xl:h-96
                    background-circle-parentig
                    rounded-full
                    flex
                    justify-center
                    items-center
                  "
                >
                  <img
                    class="xl:w-32 2xl:w-52"
                    src="../../assets/iconos/Artboard3.png"
                    alt=""
                  />
                </div>
              </div>
              <img
                class="w-full xl:w-72 2xl:w-35 transform"
                src="../../assets/btns/btn-AMGH.png"
              />
            </div>
          </div>
          <!-- 2 columna -->
          <div class="h-full">
            <div
              @click="goTo('https://teleton.org/')"
              class="w-full h-full flex flex-col justify-center items-center"
            >
              <div class="rounded-full shadow-parenting-circle">
                <div
                  class="
                    w-52
                    h-52
                    sm:w-24 sm:h-24
                    md:w-32 md:h-32
                    lg:w-32 lg:h-32
                    xl:w-52 xl:h-52
                    2xl:w-96 2xl:h-96
                    background-circle-parentig
                    rounded-full
                    flex
                    justify-center
                    items-center
                  "
                >
                  <img
                    class="xl:w-32 2xl:w-52"
                    src="../../assets/iconos/Artboard2.png"
                    alt=""
                  />
                </div>
              </div>
              <img
                class="w-full xl:w-72 2xl:w-35 transform"
                src="../../assets/btns/btn-FT.png"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="w-full h-3/12 flex justify-center items-center">
        <div class="w-1/2 xl:w-1/4 flex justify-center items-center">
          <img
            class="object-contain w-full"
            src="../../assets/animals/parentingImg.png"
            alt=""
          />
        </div>
      </div>
      <!-- <div class="w-full h-3/12 flex justify-center items-center">
        <div class="w-3/4 h-1/6 flex justify-center items-center">
          <img
            class="object-contain w-20 h-6"
            src="../../assets/iconos/biogen.png"
            alt=""
          />
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
export default {
  components: {
    MenuHeaderComponentVue,
  },
  computed: {},
  methods: {
    goTo(url) {
      window.open(url);
    },
  },
};
</script>

<style>
.btn-Asociaciones {
  background-image: url("../../assets/btns/btn-generic.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.bg-green-perso {
  background: linear-gradient(
    270deg,
    #c7f492 30.66%,
    rgba(254, 244, 0, 0.5) 103.79%
  );
}
.card-option-medic-association {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(2px);
  border-radius: 15px;
}
</style>