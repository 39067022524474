<template>
<MenuHeaderComponentVue :isAnyOther="true" />
   <div class=" fixed inset-0 w-screen h-screen" :class="getBackgroundStyle">
     <div class="absolute top-0 z-0 w-full h-full">
        <div class="w-full h-2/12"></div>
        <div class="w-full h-10/12 object-fill cloudmiddle"></div>
        <div class="h-1/12">
          <div class="w-full h-full flex flex-col justify-between"></div>
        </div>
      </div>
     <div @click="goToGame" class="fixed inset-0 w-full h-full z-10">
        <div class="w-full h-1/12 "></div>
        <div class="w-full h-2/12  flex flex-col justify-center items-center">
            <p class="text-center text-white text-xl
              sm:text-3xl
              md:text-4xl
              xl:text-5xl
              2xl:text-8xl
              md:tracking-wide
              font-normal text-shadow">Escape de Tuberías</p>
            <p class="text-center text-white font-normal
        text-xl
        sm:text-sm
        md:text-2xl
        2xl:text-6xl
        text-shadow">{{getLeveSelectedGameThree.title}}</p>
        </div>
        <div class="w-full h-6/12  flex flex-col justify-center items-center">
            <div class="w-full transform translate-y-1/4 flex justify-center h-3/12">
            <!-- agregar hidden en caso de que no mejore ratty -->
            <img class="rotate-img object-contain" src="../../assets/animals/ratttyDialog.png" alt="">

            </div>
            <div class="w-10/12 h-9/12 background-circles-leve-instructions flex flex-col justify-center items-center">
                <img class="w-16 hidden xl:w-24 2xl:w-52"  src="../../assets/iconos/handtap.png" alt="">
                <p class="w-8/12 lg:w-4/12 text-center  text-white font-normal
        text-xl
        sm:text-sm
        md:text-base
        2xl:text-6xl
        text-shadow">¡Gira las tuberías en el orden correcto haciendo click sobre ellas!</p>
            </div>
        </div>
        <div class="w-full h-3/12 ">
            <img class="h-full w-full object-contain object-bottom" :src="getLeveSelectedGameThree.imgSceneSlector" alt="">
        </div>
     </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MenuHeaderComponentVue from '../../components/header/MenuHeaderComponent.vue'
import router from '../../router'
export default {
    components: {
        MenuHeaderComponentVue,
    },
    methods: {
        ...mapActions(['onSelectInitScene','onSelectedLeveGameThree']),
        goToGame(){
             router.push('/game-tuberias')
        }
    },
    computed: {
        ...mapGetters(['getLeveSelectedGameThree','getBackgroundStyle'])
    },
    mounted () {
    if(!this.getLeveSelectedGameThree.imgSceneSlector){
       
      router.push("/")
    }
  },
}
</script>

<style>
.background-circles-leve-instructions{
  background-image: url('../../assets/fondos/threecirlces.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.rotate-img {
  transform: scaleX(-1);
}
</style>