<template>
  <div
    class="
      fixed
      top-0
      z-50
      w-screen
      h-12
      xl:h-24
      2xl:h-40
      flex
      justify-between
      px-9
    "
    :class="paddingtop ? 'pt-8' : 'pt-0'"
  >
    <div
      v-if="isAnyOther"
      @click="goBack"
      class="h-full w-10 2xl:w-24 flex justify-start items-center"
    >
      <img
        v-if="isAnyOther"
        class="object-contain xl:w-14 2xl:w-32"
        src="../../assets/iconos/Flecha_Izquierda.png"
        alt=""
      />
    </div>
    <div
      v-if="!isAnyOther"
      @click="goToParenting"
      class="h-full w-10 xl:w-16 2xl:w-32 flex justify-center items-center"
    >
      <img
        v-if="!isInsideGame && !isGreen && !isAnyOther"
        class="object-contain xl:w-14 2xl:w-32"
        src="../../assets/iconos/menu.png"
        alt=""
      />
      <img
        v-if="isGreen"
        class="object-contain xl:w-14 2xl:w-32"
        src="../../assets/iconos/greenMenu.png"
        alt=""
      />
    </div>
    <div class="h-full w-13 xl:w-40 2xl:w-48 flex justify-center items-center">
      <img
        class="object-contain w-20 xl:w-32 2xl:w-48 h-full"
        src="../../assets/iconos/biogen.png"
        alt=""
      />
    </div>
    <div
      @click="goToHomeSelector"
      class="h-full w-10 xl:w-16 2xl:w-32 flex justify-center items-center"
    >
      <img
        v-if="isInsideGame"
        class="object-contain xl:w-14 2xl:w-32 p-1"
        src="../../assets/iconos/homeOrange.png"
        alt=""
      />
      <img
        v-if="!isInsideGame && !isGreen"
        class="object-contain xl:w-14 2xl:w-32"
        src="../../assets/iconos/home.png"
        alt=""
      />
      <img
        v-if="isGreen"
        class="object-contain xl:w-14 2xl:w-32"
        src="../../assets/iconos/greenHome.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from "../../router";
export default {
  props: {
    isInsideGame: {
      type: Boolean,
      default: false,
    },
    isGreen: {
      type: Boolean,
      default: false,
    },
    // Antes IsSelectorGame con los cambios de el cliente se aplian los lugares donde no debe ser visto este icono
    isAnyOther: {
      type: Boolean,
      default: false,
    },
    paddingtop: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["onClearGameThree"]),
    goToHomeSelector() {
      router.push("/selector-edad");
      this.onClearGameThree();
    },
    goToParenting() {
      router.push("/parenting");
      this.onClearGameThree();
    },
    goBack() {
      router.go(-1);
    },
  },
};
</script>

<style>
</style>