<template>
<div v-if="isMovileDevice">  
  <FirstViewVue/>  
</div>
<div v-else>
  <AlertDesktopComponentVue/>
</div>
</template>

<script>
import AlertDesktopComponentVue from '../components/alerts/AlertDesktopComponent.vue'
import FirstViewVue from './FirstView.vue'
import {isDevice } from '../utils/isDevice'
export default {
  name: 'Home',
  data() {
    return {
      isMovileDevice: false
    }
  },
  components: {
    FirstViewVue,
    AlertDesktopComponentVue,
  },
  created () {
    this.isMovileDevice = isDevice()    
  },
}
</script>
