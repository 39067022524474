<template>
 <div v-if="getLeveTwoSelected.scenes">
    <MenuHeaderComponentVue :isAnyOther="true" />
  <div
  
    class="fixed inset-0 blur-bg"
    :style="{
      'background-image':
        'url(' + getLeveTwoSelected.scenes[0].imgBackground + ')',
    }"
  ></div>
  <div class="fixed inset-0 bg-black bg-opacity-20">
    <div class="gameOne w-full h-full flex justify-center items-center">
      <Swiper
        class="
          mySwiper
          w-full
          h-full
          max-h-837 max-w-375
          xl:max-h-1037 xl:max-w-575
        "
        :loop="false"
        :navigation="true"
        :pagination="{ clickable: true }"
        :slidesPerView="1"
        :spaceBetween="0"
      >
        <swiper-slide v-for="(scene, x) in getLeveTwoSelected.scenes" :key="x">
          <GameTwoViewCardVue :scene="scene" :sceneNumber="x" />
        </swiper-slide>
      </Swiper>
    </div>
  </div>
  <div
    v-if="isFisrtEntry"
    @click="clickFirstEntry"
    class="fixed inset-0 bg-opacity-70 flex justify-center items-center"
  >
    <div
      class="
        w-full
        h-full
        z-20
        flex flex-col
        absolute
        top-0
        justify-center
        items-center
        background-gameTwo
      "
    >
      <!-- TituloCurve -->
      <div class="w-full h-2/12 flex justify-center items-center">
        <p
          id="titleGameCurved"
          class="
            text-white text-xl
            sm:text-3xl
            md:text-4xl
            xl:text-5xl
            2xl:text-8xl
            md:tracking-wide
            font-normal
            text-center
            transform
            text-shadow
            tracking-norma
            -translate-y-2/4
          "
        >
          {{ getLeveTwoSelected.title }}
        </p>
      </div>
      <div
        class="
          w-full
          h-auto
          2xl:h-2/12
          flex
          transform
          -translate-y-1/3
          justify-center
        "
      >
        <img
          class="sm:hidden md:block md:w-1/3 object-cover"
          src="../../assets/animals/zacSm.png"
          alt=""
        />
      </div>
      <div
        class="
          gameTwo
          h-auto
          w-full
          z-50
          flex
          transform
          -translate-y-1/3
          justify-center
          items-center
          align-middle
        "
      >
        <div class="swiper-button-prev"></div>
        <!-- Circulo -->
        <div
          class="
            transform
            sm:translate-y-0
            mt-5
            sm:mt-16
            rounded-full
            shadow-background-circle
          "
        >
          <div
            class="
              w-52
              h-52
              sm:w-52 sm:h-52
              md:w-64 md:h-64
              lg:w-72 lg:h-72
              xl:w-96 xl:h-96
              2xl:w-104 2xl:h-104
              p-5
              background-circle
              rounded-full
              flex flex-col
              justify-center
              items-center
            "
          >
            <p
              class="
                font-normal
                text-center text-white text-sm
                md:text-xl
                xl:text-2xl
                2xl:text-6xl
                text-shadow
              "
            >
              Desliza a la izquierda o derecha y encuentra todos los objetos!
            </p>
          </div>
        </div>
        <div class="swiper-button-next"></div>
      </div>
      <!-- <div class="w-1/4 h-auto flex transform -translate-y-full justify-center">
        <img
          class="object-cover"
          src="../../assets/iconos/tabInstruction.png"
          alt=""
        />
      </div> -->
    </div>
  </div>
 </div>
</template>

<script>
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.css";
import { mapGetters } from "vuex";
import GameTwoViewCardVue from "./GameTwoViewCard.vue";
import router from '../../router';
// import ArcText from "arc-text";

export default {
  data() {
    return {
      isFisrtEntry: true,
      // windowWidth: window.innerWidth,
      // windoWHeight: window.innerHeight,
    };
  },

  components: {
    MenuHeaderComponentVue,
    Swiper,
    SwiperSlide,
    GameTwoViewCardVue,
  },
  computed: {
    ...mapGetters(["getLeveTwoSelected"]),
  },
  methods: {
    clickFirstEntry() {
      this.isFisrtEntry = false;
    },
  },
created() {
  console.log(this.getLeveTwoSelected.scenes)
    if(!this.getLeveTwoSelected.scenes){
      router.push("/")
    }
  },
};
</script>

<style>
.gameOne .swiper-horizontal > .swiper-pagination-bullets {
  bottom: 13% !important;
}

.gameTwo .swiper-button-next {
  top: 53%;
}
.gameTwo .swiper-button-prev {
  top: 53%;
}

.background-gameTwo {
  backdrop-filter: blur(15px);
}
.object-container-icon {
  background-image: url("../../assets/iconos/gameTwoContainerObject.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}
.blur-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -o-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
}
</style>