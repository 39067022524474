<template>
  <MenuHeaderComponentVue :isAnyOther="true" />
  <div
    class="
      fixed
      inset-0
      w-screen
      h-screen
      bg-gradient-to-b
      from-19e4ec
      to-0da2a3
    "
  >
    <!-- Nubes -->
    <div class="absolute z-10 top-0 w-full h-full cloud-instructions"></div>
    <!-- Contenedor -->
    <div class="fixed inset-0 z-20 flex flex-col top-0 w-full h-full">
      <!-- Viviendo con ame -->
      <div class="w-full h-2/12 flex flex-col justify-end items-center pb-8">
        <p
          class="
            font-normal
            text-white
            sm:text-3xl
            md:text-4xl
            xl:text-5xl
            2xl:text-8xl
            text-shadow
          "
        >
          Viviendo con AME
        </p>
      </div>
      <!-- Swiper -->
      <div class="w-full h-5/12 flex justify-center items-center">
        <Swiper
          class="z-10 swiper w-full h-full flex justify-center"
          :loop="true"
          :slidesPerView="'auto'"
          :navigation="true"
          :spaceBetween="20"
          :centeredSlides="true"
          @slideChange="change"
        >
          <swiper-slide
            class="flex justify-center"
            v-for="(card, i) in getCardsAme"
            :key="i"
          >
            <div
              class="
                w-69-8
                h-full
                flex flex-col
                justify-center
                items-center
                bg-yellow-300
                rounded-3xl
              "
            >
              <div
                class="
                overflow-y-auto
                  w-89-77
                  h-11/12
                  flex flex-col
                "
              >
                <p class="font-bold text-center text-white text-xl">{{ card.title }}</p>
                <br />
                <p class="font-normal text-center text-white text-lg px-4">
                  {{ card.text }}
                </p>
              </div>
            </div>
          </swiper-slide>
        </Swiper>
      </div>
      <!-- Despliza para más consejos -->
      <div class="h-1/12 w-full flex justify-center items-center">
        <p
          class="
            text-center
            w-full
            text-white
            font-normal
            text-xl
            sm:text-sm
            md:text-xl
            xl:text-2xl
            2xl:text-6xl
            text-shadow
          "
        >
          Desliza para más consejos
        </p>
      </div>
      <!-- Flechas direccionales -->
      <div class="w-full h-1/12 flex justify-center items-center">
        <img
          class="object-contain w-40 xl:w-52 2xl:w-96 h-full"
          src="../../assets/iconos/flechasDireccionales.png"
          alt=""
        />
      </div>

      <!-- papas zac -->
      <div class="w-full h-3/12 flex justify-center items-center">
        <div class="w-2/5 xl:w-1/4 flex justify-center items-center">
          <img
            class="object-contain w-full"
            src="../../assets/personajes/Tableta_PapasHabla.png"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MenuHeaderComponentVue from "../../components/header/MenuHeaderComponent.vue";
import router from "../../router";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import "swiper/swiper-bundle.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    MenuHeaderComponentVue,
    Swiper,
    SwiperSlide,
  },
  computed: {
    ...mapGetters(["getCardsAme"]),
  },
  methods: {
    ...mapActions(["onGetCardsAmeApi"]),
    goTo(url) {
      router.push(url);
    },
  },
  mounted() {
    this.onGetCardsAmeApi();
  },
};
</script>

<style>
.card-middle-living-ame {
  background: radial-gradient(
    97.57% 210.75% at 0.9% 2.98%,
    rgba(255, 255, 255, 0.4) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  backdrop-filter: blur(42px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 15px;
}
/* Para ser mas exactos */
.w-69-8 {
  width: 69.8067%;
}
.w-89-77 {
  width: 89.775%;
}
/* .h-85-26 {
  height: 85.2691%;
} */
/* .h-39-73{
    height: 39.73%;
} */
.border-3 {
  border-width: 3px;
}

.gradient-border-yellow {
  background-image: radial-gradient(
      circle at 100% 100%,
      transparent 12px,
      #fff6d0 12px,
      #fff6d0 15px,
      transparent 15px
    ),
    linear-gradient(to right, #fff6d0, #fecb00),
    radial-gradient(
      circle at 0% 100%,
      transparent 12px,
      #fecb00 12px,
      #fecb00 15px,
      transparent 15px
    ),
    linear-gradient(to bottom, #fecb00, #fe8400),
    radial-gradient(
      circle at 0% 0%,
      transparent 12px,
      #fe8400 12px,
      #fe8400 15px,
      transparent 15px
    ),
    linear-gradient(to left, #fe8400, #fff6d0),
    radial-gradient(
      circle at 100% 0%,
      transparent 12px,
      #fff6d0 12px,
      #fff6d0 15px,
      transparent 15px
    ),
    linear-gradient(to top, #fff6d0, #fff6d0);
  background-size: 15px 15px, calc(100% - 30px) 3px, 15px 15px,
    3px calc(100% - 30px);
  background-position: top left, top center, top right, center right,
    bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
}

.gradient-border-blue {
  background-image: radial-gradient(
      circle at 100% 100%,
      transparent 12px,
      #ffffff 12px,
      #ffffff 15px,
      transparent 15px
    ),
    linear-gradient(to right, #ffffff, #4baeff00),
    radial-gradient(
      circle at 0% 100%,
      transparent 12px,
      #4baeff00 12px,
      #4baeff00 15px,
      transparent 15px
    ),
    linear-gradient(to bottom, #4baeff00, #4baeff),
    radial-gradient(
      circle at 0% 0%,
      transparent 12px,
      #4baeff 12px,
      #4baeff 15px,
      transparent 15px
    ),
    linear-gradient(to left, #4baeff, #ffffff),
    radial-gradient(
      circle at 100% 0%,
      transparent 12px,
      #ffffff 12px,
      #ffffff 15px,
      transparent 15px
    ),
    linear-gradient(to top, #ffffff, #ffffff);
  background-size: 15px 15px, calc(100% - 30px) 3px, 15px 15px,
    3px calc(100% - 30px);
  background-position: top left, top center, top right, center right,
    bottom right, bottom center, bottom left, center left;
  background-repeat: no-repeat;
}
</style>
