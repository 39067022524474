import router from "../../router";

export default {
  state: {
    isUserAdminLogin: false,
  },
  mutations: {
    setUserLogin(state, payload) {
      state.isUserAdminLogin = payload;
    },
  },
  actions: {
    onLogin({ commit }, payload) {
      const { password, userName } = payload;
      let myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", `*`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "POST",
        body: JSON.stringify({ password: password, userName: userName }),
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`https://coral-app-l7vk8.ondigitalocean.app/api/login`, requestOptions)
        .then((response) => {
          console.log(response);
          if (response.status === 200) {
            commit("setUserLogin", true);
            localStorage.setItem("isLogged", true);
            router.push("/cms");
          } else {
            commit("setUserLogin", false);
            alert("contraseña incorrecta!");
          }
        })
        .catch((error) => console.log("error", error));
    },
    onCreateCard({ commit }, { title = "", text = "" }) {
      let myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", `*`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "POST",
        body: JSON.stringify({ title: title, text: text }),
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`https://coral-app-l7vk8.ondigitalocean.app/api/ame`, requestOptions)
        .then((response) => response.json())
        .then((dataNewCard) => {
          if (dataNewCard.ok) {
            const newCard = dataNewCard.ameCard;
            commit("setOnlyOneCard", newCard);
          } else {
            alert("algo salio mal!");
          }
        })
        .catch((error) => console.log("error", error));
    },
    // update
    onUpdateCard({ commit }, { title = "", text = "", id }) {
      let myHeaders = new Headers();

      myHeaders.append("Access-Control-Allow-Origin", `*`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "PUT",
        body: JSON.stringify({ title: title, text: text }),
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(`https://coral-app-l7vk8.ondigitalocean.app/api/ame/${id}`, requestOptions)
        .then((response) => response.json())
        .then((updateCard) => {
          if (updateCard.ok) {
            const newCard = updateCard.card;
              commit("setUpdateOneCard", { id, newCard });
          } else {
            console.log(updateCard);
            alert("algo salio mal!");
          }
        })
        .catch((error) => console.log("error", error));
    },

    onDeleteCard({ commit }, id) {
      let myHeaders = new Headers();
      myHeaders.append("Access-Control-Allow-Origin", `*`);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "DELETE",
        body: JSON.stringify({ id }),
        headers: myHeaders,
        redirect: "follow",
      };
      fetch(`https://coral-app-l7vk8.ondigitalocean.app/api/ame/delete`, requestOptions)
        .then((response) => response.json())
        .then((isDeleted) => {
          if (isDeleted.ok) {
            commit("deleteOneCard", id);
          } else {
            alert("algo salio mal!");
          }
        })
        .catch((error) => console.log("error", error));
    },
  },
  getters: {
    getUserAdminLogin: (state) => state.isUserAdminLogin,
  },
};
